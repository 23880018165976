const { types } = require("../types");

const initialState = {
  auth: !!localStorage.getItem("token"),
  role: localStorage.getItem("role"),
  user: {},
  id: localStorage.getItem("id"),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // Ingreso
    case types.authIngreso:
      return {
        ...state,
        auth: true,
        role: action.payload.role,
        user: action.payload,
      };
    // Salida
    case types.authSalida:
      return {
        ...state,
        auth: false,
        user: {},
        role: null,
      };
    // Token expirado
    case types.authTokenExp:
      return {
        ...state,
        auth: false,
        user: {},
        role: null,
      };

    default:
      return state;
  }
};

export default authReducer;
