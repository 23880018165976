import axiosClient from "./axiosClient";

const axiosInterceptor = () => {
  let token = localStorage.getItem("token");
  if (token) {
    axiosClient.defaults.headers.common["token"] = token;
  } else {
    delete axiosClient.defaults.headers.common["token"];
  }
};

export default axiosInterceptor;
