import React, { useEffect } from "react";
// Redux
import { useDispatch } from "react-redux";
// Componentes
import { authValidation } from "../../redux/actions/auth";
// import Footer from "./Footer";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authValidation());
  }, [dispatch]);
  return (
    <>
      {children}
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
