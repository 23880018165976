import React from "react";
import { Link } from "react-router-dom";
import { END_POINT } from "../../config/urlApi";

import PropTypes from "prop-types";

const LoadImage = React.lazy(() => import("../LoadImage"));
const LoadAudio = React.lazy(() => import("../LoadAudio"));

const HomeCard = ({ episode, cap }) => {
  let { url_name, image, program, title, description, file } = episode;
  return (
    <div className="col-12">
      <div className="container">
        <div className={`card mb-3 ${cap === 0 && "text-gris-hdco"}`}>
          <div className="row no-gutters">
            <div className="col-md-4">
              <React.Suspense fallback={<div>Cargando imagen</div>}>
                <LoadImage dir={`${END_POINT}/episode/image/${image}`} />
              </React.Suspense>
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title text-black" style={{ fontWeight: "bold" }}>{program.name}</h5>
                <h2 className="card-subtitle text-muted" style={{ fontWeight: "bold" }}>{title}</h2>
                <p className="card-text text-justify">{description}</p>
                <br />
                <div className="row">
                  <div className="col-6">

                    <React.Suspense fallback={<div>Cargando audio</div>}>
                      <LoadAudio dir={`${END_POINT}/episode/file/${file}`} />
                    </React.Suspense>
                  </div>
                  <div className="col-3">
                    <Link className="btn btn-block" to={`/episode/${url_name}`} style={{ backgroundColor: "rgb(243, 119, 32)" }}>Ver Más</Link>
                  </div>
                  <div className="col-3">
                    <a
                      href="https://open.spotify.com/show/2A9XpZldk6ahg2qkSpDi1Z?si=_WK2zfo4S_6THzSCy5ZPPw&nd=1"
                      className="btn btn-success btn-block"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ padding: ".35rem  .5rem .5rem .5rem ", }}
                    >
                      <i className="fab fa-spotify"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeCard.propTypes = {
  url_name: PropTypes.string,
  image: PropTypes.string,
  program: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  file: PropTypes.string,
};

export default HomeCard;
