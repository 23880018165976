import React from "react";
// import axiosClient from "../config/axiosClient";
import Layout from "../components/Layout/Layout";
import Footer from "../components/Layout/Footer";
import { useSelector } from "react-redux";
// import { makeList } from "../redux/actions/episode";
import { Helmet } from "react-helmet";
import Pagination from "../components/Pagination";
import HomeCard from "../components/Cards/HomeCard";


const Home = () => {
  const { data } = useSelector(state => state.episode);
  const styleSection = { backgroundImage: "url(img/banner.jpg)" };
  return (
    <Layout>
      <Helmet>
        <title>Podcast HostDime</title>
        <meta property="og:title" content="Podcast HostDime - Entrevistas, webinars y notas empresariales." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://podcast.hostdime.com.co/" />
        <meta property="og:image" content="https://podcast.hostdime.com.co/img/podcast.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="1200" />
        <meta property="og:site_name" content="HostDime" />
        <meta property="fb:app_id" content="151614201532179" />
        <meta property="og:description"
          content="Podcast HostDime, todo sobre data centers, colocation, centros de datos, servidores dedicados, tips de seguridad, SSL, dominios y tecnología en general; un episodio nuevo cada quincena" />
      </Helmet>
      <section className="imagen-header d-flex flex-column justify-content-center pt-4" style={styleSection} id="header-app">
        <div className="container">
          <div className="p-4">
            <div className="row">
              <div className="col-12 oscuree col-sm-5 text-white text-justify" >
                <div className="container p-4">
                  <h1 style={{ color: "#f37720" }}>Podcast HostDime</h1>
                  <p className="text-justify">Todo sobre data centers, colocation, centros de datos, servidores dedicados, tips de seguridad, SSL, dominios y tecnología en general.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />

      {
        data.length > 0 ? (
          <div className="space">
            <div className="container-fluid space">
              <div className="row mt-4">
                {data.map(
                  (d, i) =>
                    <HomeCard key={`episode_card_${i}`} episode={d} cap={i} />
                )}
              </div>
            </div>
          </div>
        ) : <Pagination />
      }
      <Footer />
    </Layout>
  );
};

export default Home;
