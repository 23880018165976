import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HomeCard from "../components/Cards/HomeCard";
import axiosClient from "../config/axiosClient";

const Programas = () => {
  let { id } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    axiosClient
      .get("/episode/query/" + id)
      .then((r) => setData(r.data))
      .catch((e) => console.log(e.response));
  }, [id]);
  return (
    <div className="container mt-4">
      {!data.length && (
        <div className="alert alert-info">
          Este programa no tiene espisodios
        </div>
      )}

      <div className="row mt-4">
        {data.map((d, i) => (
          <HomeCard key={`episode_card_${i}`} episode={d} />
        ))}
      </div>
    </div>
  );
};

export default Programas;
