import React from "react";

const Footer = ({ fixed = false }) => {
  return (
    <footer className={`footer bg-dark pb-2 pt-2 text-white ${fixed && "fixed-bottom"}`}>
      <div className="container-fluid">
        <nav className="row text-center">
          <div className="col-12">
            <a
              href="https://co.linkedin.com/company/hostdime-colombia-sas"
              className="text-white p-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://www.facebook.com/hostdimecolombia"
              className="text-white p-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/hostdimeco/"
              className="text-white p-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://twitter.com/miHostDime"
              className="text-white p-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.youtube.com/c/HostdimeColombiaCO"
              className="text-white p-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              href="https://open.spotify.com/show/2A9XpZldk6ahg2qkSpDi1Z?si=_WK2zfo4S_6THzSCy5ZPPw&nd=1"
              className="text-white p-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-spotify"></i>
            </a>
          </div>
          <div className="col-12">&copy; Podcast de HostDime 2021</div>
          <div className="col-12 text-warning">
            <a
              href="https://www.hostdime.com.co"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link text-warning"
            >
              Un producto de HostDime Colombia.
            </a>
          </div>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
