import React from "react";
// Formularios
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { postLogin } from "../redux/actions/auth";
import Layout from "../components/Layout/Layout";
import { Helmet } from 'react-helmet'

const IngresoPage = () => {
  const dispatch = useDispatch();
  let {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Campo requerido")
        .email("Formato de correo invalido"),
      password: yup
        .string()
        .required("Campo requerdio")
        .min(6, "Este campo requiere de minimo 6 caracteres"),
    }),
    onSubmit: (formulario) => {
      dispatch(postLogin(formulario));
    },
  });
  let { email, password } = values;
  return (
    <Layout>

      <Helmet>
        <title>Podcast HostDime | Login</title>
        <meta name="description" content="meta description de ingreso"></meta>
      </Helmet>
      <br />
      <br />
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card">
            <div className="card-header text-center">
              <h1>Ingreso</h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                {/* Email */}
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Correo electronico"
                    className="form-control"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                </div>
                {touched.email && errors.email && (
                  <div className="alert alert-danger">{errors.email}</div>
                )}
                {/* Password */}
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Contraseña"
                    className="form-control"
                    value={password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.password && errors.password && (
                  <div className="alert alert-danger">{errors.password}</div>
                )}
                {/* Button */}
                <div className="form-group">
                  <button type="submit" className="btn btn-warning btn-block">
                    Ingresar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IngresoPage;
