const { types } = require("../types");

const initialState = {
  data: [],
};

const episodeReducer = (state = initialState, action) => {
  switch (action.type) {
    // List
    case types.episodeList:
      return { data: action.payload };

    default:
      return state;
  }
};

export default episodeReducer;
