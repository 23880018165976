import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// Componentes
import Layout from "../components/Layout/Layout";
import EpisodioModal from "../components/Modals/EpisodioModal";
import BodyEpisodio from "../components/Tables/BodyEpisodio";
// Configuraciones
import axiosClient from "../config/axiosClient";
import axiosInterceptor from "../config/axiosInterceptor";

const EpisodioList = () => {
  const select = useRef();
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);

  useEffect(() => {
    handleList();
    return () => {
      localStorage.removeItem("episode");
    };
  }, []);
  const handleList = () => {
    axiosInterceptor();
    axiosClient
      .get("/episode/all")
      .then((r) => setData(r.data.data))
      .catch((e) => console.log(e.response));
  };
  const handleSelect = (data) => {
    localStorage.setItem("episode", JSON.stringify(data));
    setView(true);
    setTimeout(() => {
      let btn = select.current;
      btn.click();
    }, 200);
  };

  const handleClose = () => {
    setView(false);
    handleList();
  };
  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="d-flex flex-row-reverse mb-4">
          <Link to="/upload" className="btn btn-primary pr-4 pl-4">
            Agregar
          </Link>
        </div>
        <div className="table-responsive">
          <table className="table table-hover table-bordered text-center">
            <thead>
              <tr>
                <th> Imagen </th>
                <th> Titulo </th>
                <th> Descripcion </th>
                <th> File </th>
                <th> Fecha de creacion </th>
                <th> Opciones </th>
              </tr>
            </thead>
            <tbody>
              {data.map((dato, i) => (
                <BodyEpisodio
                  key={`episodio_${i}`}
                  dato={dato}
                  i={i}
                  handleList={handleList}
                  handleSelect={handleSelect}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <button
        ref={select}
        className="d-none"
        data-toggle="modal"
        data-target="#newModal"
      />
      {view && <EpisodioModal handleClose={handleClose} />}
    </Layout>
  );
};

export default EpisodioList;
