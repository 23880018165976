import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// Componentes
import Layout from "../components/Layout/Layout";
import UsuarioModal from "../components/Modals/UsuarioModal";
import BodyUsuario from "../components/Tables/BodyUsuario";
// Configuraciones
import axiosClient from "../config/axiosClient";
import axiosInterceptor from "../config/axiosInterceptor";

const UsuarioList = () => {
  const select = useRef();
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);

  useEffect(() => {
    handleList();
    return () => {
      localStorage.removeItem("user");
    };
  }, []);
  const handleList = () => {
    axiosInterceptor();
    axiosClient
      .get("/user/list")
      .then((r) => setData(r.data.data))
      .catch((e) => console.log(e.response));
  };
  const handleSelect = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    setView(true);
    setTimeout(() => {
      let btn = select.current;
      btn.click();
    }, 200);
  };

  const handleClose = () => {
    setView(false);
    handleList();
  };

  return (
    <Layout>
      <div className="container mt-4">
        <div className="d-flex flex-row-reverse mb-4">
          <Link to="/add/usuario" className="btn btn-primary pr-4 pl-4">
            Agregar
          </Link>
        </div>
        <div className="table-responsive">
          <table className="table table-hover table-bordered text-center">
            <thead>
              <tr>
                <th> Imagen </th>
                <th> Nombre </th>
                <th> Correo </th>
                <th> Rol </th>
                <th> Fecha de creacion </th>
                <th> Opciones </th>
              </tr>
            </thead>
            <tbody>
              {data.map((dato, i) => (
                <BodyUsuario
                  key={`usuario_${i}`}
                  dato={dato}
                  i={i}
                  handleList={handleList}
                  handleSelect={handleSelect}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <button
        ref={select}
        className="d-none"
        data-toggle="modal"
        data-target="#newModal"
      />
      {view && <UsuarioModal handleClose={handleClose} />}
    </Layout>
  );
};

export default UsuarioList;
