import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon } from "react-share"
import React from 'react'

const SocialNetworks = ({ episode }) => {
    return (
        <>
            <FacebookShareButton url={episode}>
                <FacebookIcon size={32} />
            </FacebookShareButton>
            <TwitterShareButton url={episode}>
                <TwitterIcon size={32} />
            </TwitterShareButton>
            <LinkedinShareButton url={episode}>
                <LinkedinIcon size={32} />
            </LinkedinShareButton>
            <WhatsappShareButton url={episode}>
                <WhatsappIcon size={32} />
            </WhatsappShareButton>
            <a
                href="https://open.spotify.com/show/2A9XpZldk6ahg2qkSpDi1Z?si=_WK2zfo4S_6THzSCy5ZPPw&nd=1"
                className="text-white bg-success react-share__ShareButton"
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: ".35rem  .5rem .5rem .5rem ", }}
            >
                <i className="fab fa-spotify"></i>
            </a>

        </>
    )
}

export default SocialNetworks
