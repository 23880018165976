import React, { useEffect, useState } from 'react'
import Layout from "../components/Layout/Layout"
import { useParams } from "react-router-dom"
import axiosInterceptor from '../config/axiosInterceptor';
import axiosClient from '../config/axiosClient';
import { END_POINT, URL_PAGE } from '../config/urlApi';
import LoadAudio from '../components/LoadAudio';
import SocialNetworks from '../components/SocialNetworks';
import { Helmet } from 'react-helmet';
import Footer from '../components/Layout/Footer';

const Episode = () => {
    const { id } = useParams();

    const [episode, setEpisode] = useState({})
    useEffect(() => {
        getEpisode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEpisode = () => {
        if (id) {
            axiosInterceptor();
            axiosClient.get(`/episode/find/${id}`).then(res => setEpisode(res.data.data)).catch(console.log);
        }
    }
    return (
        <>
            <Layout>
                {episode._id ? (
                    <>
                        <Helmet>
                            <title>{episode.title} - Podcast HostDime</title>
                            <meta property="og:title" content={`Podcast HostDime - ${episode.title}`} />
                            <meta property="og:type" content="website" />
                            <meta property="og:url" content="https://podcast.hostdime.com.co/" />
                            <meta property="og:image" content={`${END_POINT}/episode/image/${episode.image}`} />
                            <meta property="og:image:width" content="1200" />
                            <meta property="og:image:height" content="1200" />
                            <meta property="og:site_name" content="HostDime" />
                            <meta property="fb:app_id" content="151614201532179" />
                            <meta property="og:description" content={episode.description} />
                        </Helmet>
                        <div className="container-fluid">
                            <div className="row mt-4">
                                <div className="col-12 col-lg-8 mx-auto">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-12 col-md-4 mx-auto">
                                                <img
                                                    src={`${END_POINT}/episode/image/${episode.image}`}
                                                    loading="lazy"
                                                    alt="imagen del episodio"
                                                    className="card-img  mx-auto mt-4"
                                                    style={{ width: "100%", maxHeight: "25rem", maxWidth: "25rem", border: ".1rem solid #f37720", borderRadius: "1rem" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="container">
                                                <h2 className="card-subtitle text-center" style={{ fontWeight: "bold" }}>{episode.title}</h2>
                                                <p className="card-text text-justify">{episode.description}</p>
                                                <br />
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <React.Suspense fallback={<div>Cargando audio</div>}>
                                                            <LoadAudio dir={`${END_POINT}/episode/file/${episode.file}`} />
                                                        </React.Suspense>
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2 text-center">
                                                        <SocialNetworks episode={URL_PAGE + "/episode/" + episode.url_name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </>)
                    : (
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-12 col-sm-6 mx-auto">
                                    <div className="alert alert-secondary">Buscando episodio</div>
                                </div>
                            </div>
                        </div>
                    )}
            </Layout>
            <Footer fixed={true} />
        </>
    )
}

export default Episode
