import React from "react";
import PropTypes from "prop-types";

const LoadAudio = ({ dir }) => {
  return (
    <audio src={dir}  controls className="reproductor" type="audio/mp3">
      Este es un elemento de audio no soportado por tu navegador
    </audio>
  );
};

LoadAudio.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default LoadAudio;
