import React, { useEffect } from 'react'
import { useState } from 'react'
import axiosClient from '../config/axiosClient'
import HomeCard from './Cards/HomeCard';

const Pagination = () => {
    const [response, setResponse] = useState({ episodes: [] });
    let { episodes, total, limit, page = 1 } = response;
    let max = Math.ceil(total / limit)
    useEffect(() => {
        getEpisodes(1);
    }, [])
    const getEpisodes = (pag = 1) => {
        axiosClient.get(`/episode/pagination?page=${pag}`).then(r => setResponse(r.data))
    }
    const styleBgColor = { backgroundColor: "rgb(243, 119, 32)" }
    const styleTColor = { color: "rgb(243, 119, 32)" }
    const handleGetEpisodes = (page) => {
        window.scrollTo(0, 0);
        getEpisodes(page);
    }
    return (
        <div>
            {
                episodes.length > 0 && (
                    episodes.map((episode, i) => <HomeCard key={`episode_card_${i}`} episode={episode} cap={i} />)
                )
            }
            <br />
            {
                episodes.length > 0 && (
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                            {
                                page !== 1 && (
                                    <li className={`page-item ${page === 1 && 'disabled'}`}>
                                        <p className="page-link" style={styleTColor} onClick={() => handleGetEpisodes(page - 1)} tabIndex="-1" aria-disabled="true">{page - 1}</p>
                                    </li>
                                )

                            }
                            <li className="page-item">
                                <span className="page-link text-dark" style={styleBgColor} > {page}</span>
                            </li>
                            {
                                page !== max && (
                                    <li className={`page-item ${page === max && 'disabled'}`}>
                                        <span className="page-link" style={styleTColor} onClick={() => handleGetEpisodes(page + 1)}>{page + 1}</span>
                                    </li>
                                )
                            }
                        </ul>
                    </nav>
                )
            }
        </div>
    )
}

export default Pagination
