import React from "react";
// Librerias
import moment from "moment";
import Swal from "sweetalert2";
// Configuraciones
import axiosInterceptor from "../../config/axiosInterceptor";
import axiosClient from "../../config/axiosClient";
import { END_POINT } from "../../config/urlApi";

const BodyProgram = ({ dato, i, handleList, handleSelect }) => {
  const handleChoose = () => {
    Swal.fire({
      text: "Esta seguro que quiere cambiar la imagen del programa",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        let inp = document.querySelector(`#image_${i}`);
        inp.click();
      }
    });
  };

  const handleImage = () => {
    let preview = document.querySelector(`#preview${i}`);
    let frm = new FormData(document.querySelector(`#frmImage${i}`));
    let inp = frm.get("image");
    let url = URL.createObjectURL(inp);
    preview.setAttribute("src", url);
    handleUpload(frm);
  };

  const handleUpload = (form) => {
    axiosInterceptor();
    axiosClient
      .put("/program/upload/" + dato._id, form)
      .then((r) => Swal.fire({ text: r.data.message, icon: "success" }))
      .catch((e) =>
        Swal.fire({ text: "la Imagen no pudo se cambiada", icon: "error" })
      );
  };
  return (
    <tr>
      <td>
        <form id={`frmImage${i}`}>
          <input
            type="file"
            id={`image_${i}`}
            name="image"
            className="d-none"
            onChange={handleImage}
          />
        </form>
        <img
          onClick={handleChoose}
          id={`preview${i}`}
          className="img-circle"
          src={`${END_POINT}/program/image/${dato.image}`}
          height="90"
          width="90"
          alt={`image_programa_${i}`}
        />
      </td>
      <td> {dato.title} </td>
      <td> {dato.description} </td>
      <td> {moment(dato.created).fromNow()} </td>
      <td>
        <span
          className="btn btn-primary mr-2 mt-2"
          onClick={() => handleSelect(dato)}
        >
          <i className="fas fa-eye"></i>
        </span>
      </td>
    </tr>
  );
};

export default BodyProgram;
