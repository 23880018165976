import React from "react";
import Layout from "../components/Layout/Layout";
// Formularios
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import axiosInterceptor from "../config/axiosInterceptor";
import axiosClient from "../config/axiosClient";

const ProgramaForm = () => {
  let {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Campo requerido"),
      description: yup.string().required("Campo requerido"),
    }),
    onSubmit: () => {
      let data = new FormData(document.querySelector("#frm"));
      let file = !!data.get("image").name;
      if (!file) {
        Swal.fire({ text: "seleccione una imagen.", icon: "warning" });
      } else {
        axiosInterceptor();
        axiosClient
          .post("/program/add", data)
          .then(async (r) => {
            await Swal.fire({ text: r.data.message, icon: "success" });
            window.location.href = "/list/programas";
          })
          .catch(console.log);
      }
    },
  });

  let { name, description } = values;

  const handleImage = () => {
    let frm = new FormData(document.querySelector("#frm"));
    let preview = document.querySelector("#preview");
    let url = URL.createObjectURL(frm.get("image"));
    preview.classList.remove("d-none");
    preview.setAttribute("src", url);
  };
  return (
    <Layout>
      <div className="row mt-4">
        <div className="col-12 col-md-8 mx-auto">
          <div className="card mb-2">
            <div className="card-header">
              <h5 className="text-center">Nuevo programa</h5>
            </div>
            <div className="card-body">
              <div className="container mb-4">
                <div className="col-12 col-md-6 mx-auto">
                  <img
                    className="d-none img-fluid"
                    alt="imagen_preview"
                    id="preview"
                  />
                </div>
              </div>
              <form id="frm" onSubmit={handleSubmit}>
                {/* Image */}
                <div className="form-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      name="image"
                      id="image"
                      className="custom-file-input"
                      onChange={handleImage}
                    />
                    <label htmlFor="image" className="custom-file-label">
                      Imagen
                    </label>
                  </div>
                </div>
                {/* Name */}
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Nombre del programa"
                    autoComplete="off"
                    value={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.name && errors.name && (
                  <div className="alert alert-danger">{errors.name}</div>
                )}
                {/* Description */}
                <div className="form-group">
                  <textarea
                    name="description"
                    className="form-control"
                    placeholder="Descripcion del programa"
                    autoComplete="off"
                    value={description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.description && errors.description && (
                  <div className="alert alert-danger">{errors.description}</div>
                )}
                {/* Button */}
                <div className="form-group">
                  <button type="submit" className="btn btn-warning btn-block">
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProgramaForm;
