import React, { useEffect, useRef, useState } from "react";
// Formularios
import { useFormik } from "formik";
import * as yup from "yup";
// Axios
import axiosInterceptor from "../../config/axiosInterceptor";
import axiosClient from "../../config/axiosClient";
// Alertas
import Swal from "sweetalert2";
import slug from "slug";

const EpisodioModal = ({ handleClose }) => {
  const closeModal = useRef();
  const formulario = useRef();
  const frmAudio = useRef();
  const [programas, setProgramas] = useState([]);
  useEffect(() => {
    axiosInterceptor();
    axiosClient.get("/program/get").then((r) => setProgramas(r.data)).catch((e) => console.log(e.response));
    return () => { localStorage.removeItem("episode"); };
  }, []);

  let respFormik = useFormik({
    initialValues: JSON.parse(localStorage.getItem("episode")),
    validationSchema: yup.object({
      title: yup.string().required("Campo requerido"),
      description: yup.string().required("Campo requerido"),
    }),
    onSubmit: (values) => {
      let data = new FormData(formulario.current);
      axiosInterceptor();
      axiosClient
        .put(`/episode/update/${values._id}`, data)
        .then((r) => {
          let { message: text } = r.data;
          Swal.fire({ text, icon: "success" });
          let sim = closeModal.current;
          sim.click();
        })
        .catch(console.log);
    },
  });
  let { handleSubmit, values, handleChange, handleBlur, errors, touched } = respFormik;
  let { program, title, description, url_name } = values;
  let u_name = slug(title);
  url_name = u_name;
  const handleAudio = () => {
    let frm = new FormData(frmAudio.current);
    let preview = document.querySelector("#previewAudio");
    let url = URL.createObjectURL(frm.get("file"));
    preview.classList.remove("d-none");
    preview.setAttribute("src", url);
    // Actualizar audio
    const swalButtons = Swal.mixin({ customClass: { confirmButton: 'btn btn-success', cancelButton: 'btn btn-danger' } })

    swalButtons.fire({
      title: 'Segur@ que desea cambiar el audio?',
      text: "Este cambio no es reversible!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, cambiar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await handleSubmitAudio();
        if(res){
          swalButtons.fire('Episodio', 'Audio cambiado!', 'success');
        }else{
          swalButtons.fire('Episodio', 'No se pudo cambiar el audio!', 'error');
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalButtons.fire('Cancelado', 'El audio no fue cambiado', 'error')
      }
    })
    // fin audio
  };

  const handleSubmitAudio = (e) => {
    let data = new FormData(frmAudio.current);
    axiosInterceptor();
    return axiosClient.put(`/episode/file/${values._id}`, data).then((r) => true).catch(e => false);
  }
  return (
    <div className="modal fade" id="newModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Episodio</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={handleClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container mb-4">
              <div className="col-12 col-md-8 mx-auto">
                <audio style={{ width: "100%" }} id="previewAudio" className="d-none" controls type="audio/mp3">
                  Este es un elemento de audio no soportado por tu navegador, prueba con otro
                </audio>
              </div>
            </div>
            <form onSubmit={handleSubmitAudio} ref={frmAudio} >
              {/* File */}
              <div className="form-group">
                <div className="custom-file">
                  <input type="file" name="file" id="file" className="custom-file-input" onChange={handleAudio} />
                  <label htmlFor="image" className="custom-file-label">Audio</label>
                </div>
              </div>
            </form>

            <form onSubmit={handleSubmit} id="frmUpdate" ref={formulario}>
              {/* Program */}
              <div className="form-group">
                <select className="form-control" name="program" defaultValue={program} onChange={handleChange} onBlur={handleBlur}>
                  {programas.map((p, i) => (
                    <option key={`programa_opc_${i}`} value={p._id}> {p.title} </option>
                  ))}
                </select>
              </div>
              {touched.program && errors.program && (
                <div className="alert alert-danger">{errors.role}</div>
              )}
              {/* Title */}
              <div className="form-group">
                <input type="text" className="form-control" autoComplete="off" name="title" value={title} onChange={handleChange} onBlur={handleBlur} />
              </div>
              {touched.title && errors.title && (
                <div className="alert alert-danger">{errors.title}</div>
              )}
              {/* URL Name */}
              <div className="form-group">
                <input type="text" className="form-control" autoComplete="off" name="url_name" value={url_name} onChange={handleChange} onBlur={handleBlur} readOnly />
              </div>
              {/* Description */}
              <div className="form-group">
                <textarea name="description" className="form-control" value={description} onChange={handleChange} onBlur={handleBlur} />
              </div>
              {touched.description && errors.description && (
                <div className="alert alert-danger">{errors.description}</div>
              )}
              <div className="form-group">
                <button type="submit" className="btn btn-warning btn-block">Guardar</button>
              </div>
            </form>
            <button ref={closeModal} data-dismiss="modal" onClick={handleClose} className="d-none" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpisodioModal;
