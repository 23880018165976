import React, { useEffect, useRef, useState } from "react";
// Componentes
import Layout from "../components/Layout/Layout";
import ProgramModal from "../components/Modals/ProgramModal";
// Configuraciones
import axiosClient from "../config/axiosClient";
import axiosInterceptor from "../config/axiosInterceptor";
import BodyProgram from "../components/Tables/BodyProgram";

const ProgramaList = () => {
  const select = useRef();
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);

  useEffect(() => {
    handleList();
    return () => {
      localStorage.removeItem("program");
    };
  }, []);

  const handleList = () => {
    axiosInterceptor();
    axiosClient
      .get("/program/list")
      .then((r) => setData(r.data.data))
      .catch((e) => console.log(e.response));
  };

  const handleSelect = (data) => {
    localStorage.setItem("program", JSON.stringify(data));
    setView(true);
    setTimeout(() => {
      let btn = select.current;
      btn.click();
    }, 100);
  };

  const handleClose = () => {
    setView(false);
    handleList();
  };

  return (
    <Layout>
      <div className="container-fluid mt-4">

        <div className="table-responsive">
          <table className="table table-hover table-bordered text-center">
            <thead>
              <tr>
                <th> Imagen </th>
                <th> Nombre </th>
                <th> Descripcion </th>
                <th> Fecha de creacion </th>
                <th> Opciones </th>
              </tr>
            </thead>
            <tbody>
              {data.map((dato, i) => (
                <BodyProgram
                  key={`programa_${i}`}
                  dato={dato}
                  i={i}
                  handleList={handleList}
                  handleSelect={handleSelect}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <button
        ref={select}
        className="d-none"
        data-toggle="modal"
        data-target="#newModal"
      />
      {view && <ProgramModal handleClose={handleClose} />}
    </Layout>
  );
};

export default ProgramaList;
