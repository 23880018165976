import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// Componentes
import Navigation from "../components/Navigation";
// Routes
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
// Pages
import Home from "../pages/Home";
import IngresoPage from "../pages/IngresoPage";
import ProgramaForm from "../pages/ProgramaForm";
import ProgramaList from "../pages/ProgramaList";
import UsuarioList from "../pages/UsuarioList";
import UsuarioForm from "../pages/UsuarioForm";
import Upload from "../pages/Upload";
import EpisodioList from "../pages/EpisodioList";
import Programas from "../pages/Programas";
import Episode from "../pages/Episode"
// analytics
// import ReactGA from 'react-ga';
// import { createBrowserHistory } from 'history';

const AppRouter = () => {
  // const history = createBrowserHistory();

  // Initialize google analytics page view tracking
  // history.listen(location => {
  //   ReactGA.set({ page: location.pathname }); // Update the user's current page
  //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
  // });
  return (
    <Router>
      <Navigation />
      <div>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/episode/:id" exact component={Episode} />
          <PublicRoutes path="/ingreso" exact component={IngresoPage} />
          <PrivateRoutes
            path="/list/programas"
            exact
            component={ProgramaList}
          />
          <PrivateRoutes path="/list/usuarios" exact component={UsuarioList} />
          <PrivateRoutes
            path="/list/episodios"
            exact
            component={EpisodioList}
          />
          <PrivateRoutes path="/add/programa" exact component={ProgramaForm} />
          <PrivateRoutes path="/add/usuario" exact component={UsuarioForm} />
          <PrivateRoutes path="/upload" exact component={Upload} />
          <Route path="/programa/view/:id" exact component={Programas} />

          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
};

export default AppRouter;
