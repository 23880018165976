import React, { useRef } from "react";
// Formularios
import { useFormik } from "formik";
import * as yup from "yup";
// Axios
import axiosInterceptor from "../../config/axiosInterceptor";
import axiosClient from "../../config/axiosClient";
// Alertas
import Swal from "sweetalert2";
import { END_POINT } from "../../config/urlApi";

const ProgramModal = ({ handleClose }) => {
  const closeModal = useRef();
  const cop = useRef();
  let {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: JSON.parse(localStorage.getItem("program")),
    validationSchema: yup.object({
      title: yup.string().required("Campo requerido"),
      description: yup.string().required("Campo requerido"),
      api: yup.string().required("campo requerido"),
      page: yup.string().required("campo requerido"),
    }),
    onSubmit: (data) => {
      axiosInterceptor();
      axiosClient
        .put(`/program/update/${data._id}`, data)
        .then((r) => {
          let { message: text } = r.data;
          Swal.fire({ text, icon: "success" });
          let sim = closeModal.current;
          sim.click();
        })
        .catch(console.log);
    },
  });
  let { api, page, title, description } = values;
  const handleCopy = () => {
    let url_copy = cop.current;
    url_copy.select();
    document.execCommand("copy");
    Swal.fire({ text: "url copiada ", icon: "success" });
  };
  return (
    <div
      className="modal fade"
      id="newModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Programa
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  ref={cop}
                  type="text"
                  value={END_POINT + "/rss/rss.xml"}
                  className="form-control"
                  readOnly
                />
                <div className="input-group-append">
                  <span
                    className="btn btn-block btn-danger"
                    onClick={handleCopy}
                  >
                    Copiar
                  </span>
                </div>
              </div>
              <br />
              {/* Name */}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="title"
                  value={title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.name && errors.name && (
                <div className="alert alert-danger">{errors.name}</div>
              )}
              {/* Description */}
              <div className="form-group">
                <textarea
                  name="description"
                  className="form-control"
                  value={description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.description && errors.description && (
                <div className="alert alert-danger">{errors.description}</div>
              )}
              {/* Api */}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="api"
                  value={api}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.name && errors.name && (
                <div className="alert alert-danger">{errors.name}</div>
              )}
              {/* Page */}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="page"
                  value={page}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.name && errors.name && (
                <div className="alert alert-danger">{errors.name}</div>
              )}
              <div className="form-group">
                <button type="submit" className="btn btn-warning btn-block">
                  Guardar
                </button>
              </div>
            </form>
            <button
              ref={closeModal}
              data-dismiss="modal"
              onClick={handleClose}
              className="d-none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramModal;
