import { types } from "../types";
import jwt from "jwt-decode";
import axiosClient from "../../config/axiosClient";
import Swal from "sweetalert2";

export const makeIngreso = (data) => {
  return {
    type: types.authIngreso,
    payload: data,
  };
};
export const makeSalida = () => {
  localStorage.clear();
  return {
    type: types.authSalida,
  };
};
export const makeExpired = () => {
  localStorage.clear();
  return {
    type: types.authTokenExp,
  };
};

export const authValidation = () => {
  return async (dispatch) => {
    let token = localStorage.getItem("token");
    if (token) {
      let response = await jwt(token);
      let { exp, iat } = response;
      if (exp && iat) {
        let now = new Date().getTime() / 1000;
        if (exp < now) {
          dispatch(makeExpired());
        }
      } else {
        dispatch(makeExpired());
      }
    } else {
      dispatch(makeExpired());
    }
  };
};

export const postLogin = (formulario) => {
  return async (dispatch) => {
    axiosClient
      .post("/auth/login", formulario)
      .then((r) => {
        let { message, user, token } = r.data;
        Swal.fire("Ingreso", message, "success");
        localStorage.setItem("token", token);
        localStorage.setItem("role", user.role);
        localStorage.setItem("id", user._id);
        dispatch(makeIngreso(user));
      })
      .catch((e) => {
        if (e.response.data) {
          let { message } = e.response.data;
          Swal.fire("Ingreso", message, "error");
        } else {
          Swal.fire("Ingreso", "Ocurrio un error al ingresar.", "error");
        }
      });
  };
};
