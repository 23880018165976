import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import axiosClient from "../config/axiosClient";
import { makeSalida } from "../redux/actions/auth";
import { makeList } from "../redux/actions/episode";

const Navigation = () => {
  const { role } = useSelector((state) => state.auth);

  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(makeSalida());
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleSearchClick = (e) => {
    e.preventDefault();
    if (search.trim() === "") {
      dispatch(makeList([]))
    } else {
      axiosClient
        .get("/episode/filter/" + search)
        .then((r) => dispatch(makeList(r.data.data)))
        .catch((e) => console.log(e.response));
    }
  };
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-dark text-white"
      role="navigation"
    >
      <div className="container-fluid">
        <NavLink to="/" className="navbar-brand">
          <img
            style={{ width: "16rem" }}
            src="img/logo-podcast-premier.png"
            alt="logo hostdime podcast"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#barraMenuCollap"
          aria-controls="barraMenuCollap"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="barraMenuCollap">
          {role !== "USER_ADMIN" ? (
            <>
              <ul className="navbar-nav mr-auto">
                {/* <li className="nav-item">
                  <NavLink
                    to="/programas"
                    className="nav-link text-center"
                  >
                    Programas
                  </NavLink>
                </li> */}
                <li className="nav-item">
                  <a
                    href="https://blog.hostdime.com.co"
                    className="nav-link text-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://www.hostdime.com.co"
                    className="nav-link text-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HostDime
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://www.hostdime.com.co/contactenos"
                    className="nav-link text-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contactanos
                  </a>
                </li>
              </ul>
              <form
                onSubmit={handleSearchClick}
                className="form-inline my-2 my-lg-0"
              >
                <input
                  type="text"
                  name="search"
                  className="form-control mr-sm-2"
                  placeholder="Buscar episodio..."
                  autoComplete="off"
                  value={search}
                  onChange={handleSearch}
                />
                <span
                  className="btn btn-outline-light my-2 my-sm-0"
                  onClick={handleSearchClick}
                >
                  <i className="fas fa-search"></i>
                </span>
              </form>
            </>
          ) : (
            <>
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink
                    to="/list/usuarios"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Usuarios
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/list/programas"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Programas
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/list/episodios"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Episodios
                  </NavLink>
                </li>
              </ul>
              <form className="form-inline my-2 my-lg-0">
                <NavLink
                  to="/upload"
                  className="btn btn-warning btn-block pl-4 pr-4 m-1"
                  activeClassName="active text-warning"
                >
                  Subir
                </NavLink>
              </form>
              <form className="form-inline my-2 my-lg-0">
                <span
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                  className="btn btn-outline-danger pl-4 pr-4 btn-block m-1"
                >
                  Salir
                </span>
              </form>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
