import React, { useRef } from "react";
// Formularios
import { useFormik } from "formik";
import * as yup from "yup";
// Axios
import axiosInterceptor from "../../config/axiosInterceptor";
import axiosClient from "../../config/axiosClient";
// Alertas
import Swal from "sweetalert2";

const UsuarioModal = ({ handleClose }) => {
  const closeModal = useRef();
  let {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: JSON.parse(localStorage.getItem("user")),
    validationSchema: yup.object({
      name: yup.string().required("Campo requerido"),
      email: yup.string().required("Campo requerido").email("Email invalido"),
      role: yup.string().required("Campo requerido"),
    }),
    onSubmit: (data) => {
      axiosInterceptor();
      axiosClient
        .put(`/user/update/${data._id}`, data)
        .then((r) => {
          let { message: text } = r.data;
          Swal.fire({ text, icon: "success" });
          let sim = closeModal.current;
          sim.click();
        })
        .catch(console.log);
    },
  });
  let { name, email, role } = values;

  return (
    <div
      className="modal fade"
      id="newModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Usuario
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              {/* Name */}
              <div className="form-group">
                <label htmlFor="name">Nombres</label>
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.name && errors.name && (
                <div className="alert alert-danger">{errors.name}</div>
              )}
              {/* Email */}
              <div className="form-group">
              <label htmlFor="name">Correo electronico</label>
                <input
                  type="email"
                  className="form-control"
                  autoComplete="off"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.email && errors.email && (
                <div className="alert alert-danger">{errors.email}</div>
              )}
              {/* Role */}
              <div className="form-group">
              <label htmlFor="role">Cargo</label>
                <select
                  className="form-control"
                  name="role"
                  defaultValue={role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="USER_ADMIN">Administrador</option>
                  <option value="USER_CLIENT">Cliente</option>
                </select>
              </div>
              {touched.role && errors.role && (
                <div className="alert alert-danger">{errors.role}</div>
              )}

              <div className="form-group">
                <button type="submit" className="btn btn-warning btn-block">
                  Guardar
                </button>
              </div>
            </form>
            <button
              ref={closeModal}
              data-dismiss="modal"
              onClick={handleClose}
              className="d-none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsuarioModal;
