import React from "react";
// Librerias
import moment from "moment";
import Swal from "sweetalert2";
// Configuraciones
import axiosInterceptor from "../../config/axiosInterceptor";
import axiosClient from "../../config/axiosClient";
import { END_POINT } from "../../config/urlApi";
import { useSelector } from "react-redux";

const BodyUsuario = ({ dato, i, handleList, handleSelect }) => {
  const { id } = useSelector((state) => state.auth);
  const handleChangeState = ({ _id, state }) => {
    Swal.fire({
      title: `Seguro que desea ${state ? "desactivar" : "activar"} este registro`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `No`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axiosInterceptor();
        axiosClient
          .put(`/user/${state ? "desactivar" : "activar"}/${_id}`)
          .then((r) => {
            let { message } = r.data;
            Swal.fire({ text: message, icon: "success" });
            handleList();
          })
          .catch(console.log);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  };
  const handleChoose = () => {
    Swal.fire({
      text: "Esta seguro que quiere cambiar la imagen del usuario",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        let inp = document.querySelector(`#image_${i}`);
        inp.click();
      }
    });
  };

  const handleImage = () => {
    let preview = document.querySelector(`#preview${i}`);
    let frm = new FormData(document.querySelector(`#frmImage${i}`));
    let inp = frm.get("image");
    let url = URL.createObjectURL(inp);
    preview.setAttribute("src", url);
    handleUpload(frm);
  };

  const handleUpload = (form) => {
    axiosInterceptor();
    axiosClient
      .put("/user/upload/" + dato._id, form)
      .then((r) => Swal.fire({ text: r.data.message, icon: "success" }))
      .catch((e) => {
        Swal.fire({ text: "la Imagen no pudo se cambiada", icon: "error" });
        console.log(e.response.data);
      });
  };

  return (
    <tr className={`table-${dato.state ? "success" : "danger"}`}>
      <td>
        <form id={`frmImage${i}`}>
          <input
            type="file"
            id={`image_${i}`}
            name="image"
            className="d-none"
            onChange={handleImage}
          />
        </form>
        <img
          onClick={handleChoose}
          id={`preview${i}`}
          className="img-circle"
          src={`${END_POINT}/user/image/${dato.image}`}
          height="90"
          width="90"
          alt={`image_usuario_${i}`}
        />
      </td>
      <td>
        {dato.name} {dato.surname}
      </td>
      <td> {dato.email} </td>
      <td> {dato.role} </td>
      <td> {moment(dato.created).fromNow()} </td>
      <td>
        {
          // eslint-disable-next-line eqeqeq
          id != dato._id && (
            <span
              className="btn btn-warning mr-2 mt-2"
              onClick={() => handleChangeState(dato)}
            >
              {!dato.state ? (
                <i className="fas fa-toggle-off"></i>
              ) : (
                  <i className="fas fa-toggle-on"></i>
                )}
            </span>
          )}
        <span
          className="btn btn-primary mr-2 mt-2"
          onClick={() => handleSelect(dato)}
        >
          <i className="fas fa-eye"></i>
        </span>
      </td>
    </tr>
  );
};

export default BodyUsuario;
