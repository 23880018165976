import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as yup from "yup";
import slug from "slug";


import axiosInterceptor from "../config/axiosInterceptor";
import Layout from "../components/Layout/Layout";
import axiosClient from "../config/axiosClient";

const Upload = () => {
  const formulario = useRef();
  const [updFile, setUpdFile] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [programas, setProgramas] = useState([]);
  const history = useHistory();
  useEffect(() => {
    axiosInterceptor();
    axiosClient.get("/program/get").then((r) => setProgramas(r.data)).catch((e) => console.log(e.response));
  }, []);

  let resUseForm = useFormik({
    initialValues: { program: "", title: "", description: "" },
    validationSchema: yup.object({
      title: yup.string().required("Campo requerido"),
      description: yup.string().required("Campo requerido"),
    }),
    onSubmit: () => {
      let frm = new FormData(formulario.current);
      let exim = !!frm.get("file").name;
      if (!exim) {
        Swal.fire("Formulario invalido", "Seleccione un Audio", "error");
      } else {
        setUploading(true);
        axiosInterceptor();
        axiosClient.post("/episode/add", frm).then((r) => {
          setUploading(false);
          Swal.fire("Episodio", "Subido exitosamente", "success");
          // Redireccionar
          history.push("/")

        }).catch((e) => {
          setUploading(false);
          Swal.fire("Subida", "Error al publicar el episodio", "error");
        });
      }
    },
  });
  let { values, handleChange, handleBlur, handleSubmit, errors, touched } = resUseForm;
  let { title, description } = values;

  const handleChangeAudio = (e) => {
    let allowedExtensions = /(.mp3)$/i;
    if (!allowedExtensions.exec(e.target.value)) {
      Swal.fire("Archivo invalido", "El archivo subido no es un audio", "error");
    } else {
      let frmData = new FormData(formulario.current);
      let preview = document.querySelector("#previewAudio");
      let url = URL.createObjectURL(frmData.get("file"));
      preview.classList.remove("d-none");
      preview.setAttribute("src", url);
      setUpdFile(true);
    }
  };

  const handleChangeImage = (e) => {
    let frmData = new FormData(formulario.current);
    let preview = document.querySelector("#previewImage");
    let url = URL.createObjectURL(frmData.get("image"));
    preview.classList.remove("d-none");
    preview.setAttribute("src", url);
  };
  let url_name = slug(title)
  return (
    <Layout>
      <div className="container-fluid mt-4 mb-4">
        <div className="row">
          <div className="col-12 col-md-8 mx-auto">
            <div className="card">
              <div className="card-header text-center">Subir Episodio</div>
              <div className="card-body">


                <div className="container mb-4">
                  <div className="col-12">
                    <div className="container">
                      <img className="d-none img-fluid" alt="imagen_preview" id="previewImage" />
                    </div>
                    <audio style={{ width: "100%" }} id="previewAudio" className="d-none" controls type="audio/mp3">
                      Este es un elemento de audio no soportado por tu
                      navegador, prueba con otro
                    </audio>
                  </div>
                </div>
                <form ref={formulario} onSubmit={handleSubmit}>
                  {/* File */}
                  <div className="form-group">
                    <div className="custom-file">
                      <input type="file" name="file" id="file" className="custom-file-input" onChange={handleChangeAudio} />
                      <label htmlFor="file" className="custom-file-label">Audio</label>
                    </div>
                  </div>
                  {
                    updFile && (
                      <>
                        {/* Title */}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            name="title"
                            placeholder="Titulo del espisodio"
                            value={title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors.title && touched.title && (
                          <div className="alert alert-danger">
                            {errors.title}
                          </div>
                        )}
                        {/* URL NAME */}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            name="url_name"
                            placeholder="Titulo del espisodio"
                            value={url_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            readOnly
                          />
                        </div>
                        {/* Description */}
                        <div className="form-group">
                          <textarea
                            name="description"
                            className="form-control"
                            placeholder="Descripcion del episodio"
                            value={description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors.description && touched.description && (
                          <div className="alert alert-danger">
                            {errors.description}
                          </div>
                        )}
                        {/* Program */}
                        <div className="form-group">
                          <select className="form-control" name="program">
                            {programas.map((p, i) => (
                              <option key={`programa_opc_${i}`} value={p._id}>
                                {p.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* File */}
                        <div className="form-group">
                          <div className="custom-file">
                            <input type="file" name="image" id="image" className="custom-file-input" onChange={handleChangeImage} />
                            <label htmlFor="image" className="custom-file-label">Portada</label>
                          </div>
                        </div>
                        {/* Button */}
                        <div className="form-group">
                          {
                            uploading
                              ? (<div className="alert alert-info text-center m-4">Subiendo...</div>)
                              : (<button type="submit" className="btn btn-warning btn-block">Guardar</button>)
                          }
                        </div>
                      </>
                    )
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Upload
