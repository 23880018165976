import React from "react";
import Layout from "../components/Layout/Layout";
// Formularios
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import axiosInterceptor from "../config/axiosInterceptor";
import axiosClient from "../config/axiosClient";

const UsuarioForm = () => {
  let {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      password: "",
      repass: "",
      role: "USER_CLIENT",
    },
    validationSchema: yup.object({
      name: yup.string().required("(Nombres) Campo requerido"),
      surname: yup.string().required("(Apellidos) Campo requerido"),
      email: yup.string().required("(Email) Campo requerido").email("Email invalido"),
      password: yup.string().required("(Password) Campo requerido"),
      repass: yup.string().required("(Re-Password) Campo requerido"),
      role: yup.string().required("(Role) Campo requerido"),
    }),
    onSubmit: (d) => {
      let data = new FormData(document.querySelector("#frm"));
      let file = !!data.get("image").name;
      if (!file) {
        Swal.fire({ text: "seleccione una imagen.", icon: "warning" });
      } else {
        if (d.password === d.repass) {
          axiosInterceptor();
          axiosClient
            .post("/user/add", data)
            .then(async (r) => {
              await Swal.fire({ text: r.data.message, icon: "success" });
              window.location.href = "/list/usuarios";
            })
            .catch(console.log);
        } else {
          Swal.fire({ text: "las contraseñas no coinciden.", icon: "warning" });
        }
      }
    },
  });
  let { name, surname, email, password, repass, role } = values;
  const handleImage = () => {
    let frm = new FormData(document.querySelector("#frm"));
    let preview = document.querySelector("#preview");
    let url = URL.createObjectURL(frm.get("image"));
    preview.classList.remove("d-none");
    preview.setAttribute("src", url);
  };
  return (
    <Layout>
      <div className="row mt-4">
        <div className="col-12 col-md-8 mx-auto">
          <div className="card mb-2">
            <div className="card-header">
              <h5 className="text-center">Nuevo Usuario</h5>
            </div>
            <div className="card-body">
              <div className="container mb-4">
                <div className="col-12 col-md-6 mx-auto">
                  <img
                    className="d-none img-fluid"
                    alt="imagen_preview"
                    id="preview"
                  />
                </div>
              </div>
              <form onSubmit={handleSubmit} id="frm">
                {/* Image */}
                <div className="form-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      name="image"
                      id="image"
                      className="custom-file-input"
                      onChange={handleImage}
                    />
                    <label htmlFor="image" className="custom-file-label">
                      Imagen
                    </label>
                  </div>
                </div>
                {/* Name */}
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Nombres"
                    autoComplete="off"
                    value={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.name && errors.name && (
                  <div className="alert alert-danger">{errors.name}</div>
                )}
                {/* Surname */}
                <div className="form-group">
                  <input
                    type="text"
                    name="surname"
                    className="form-control"
                    placeholder="Apellidos"
                    autoComplete="off"
                    value={surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.surname && errors.surname && (
                  <div className="alert alert-danger">{errors.surname}</div>
                )}
                {/* Email */}
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Correo electronico"
                    autoComplete="off"
                    value={email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.email && errors.email && (
                  <div className="alert alert-danger">{errors.email}</div>
                )}
                {/* Password */}
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Contaseña"
                    autoComplete="off"
                    value={password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.password && errors.password && (
                  <div className="alert alert-danger">{errors.password}</div>
                )}
                {/* Repass */}
                <div className="form-group">
                  <input
                    type="password"
                    name="repass"
                    className="form-control"
                    placeholder="Confirmar contraseña"
                    autoComplete="off"
                    value={repass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.repass && errors.repass && (
                  <div className="alert alert-danger">{errors.repass}</div>
                )}
                {/* Role */}
                <div className="form-group">
                  <select
                    className="form-control"
                    name="role"
                    defaultValue={role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="USER_ADMIN">Administrador</option>
                    <option value="USER_CLIENT">Cliente</option>
                  </select>
                </div>
                {touched.role && errors.role && (
                  <div className="alert alert-danger">{errors.role}</div>
                )}
                {/* Button */}
                <div className="form-group">
                  <button type="submit" className="btn btn-warning btn-block">
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UsuarioForm;
