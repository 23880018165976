import React from "react";
// Librerias
import moment from "moment";
import Swal from "sweetalert2";
// Configuraciones
import axiosInterceptor from "../../config/axiosInterceptor";
import axiosClient from "../../config/axiosClient";
import { END_POINT } from "../../config/urlApi";

const BodyEpisodio = ({ dato, i, handleList, handleSelect }) => {
  const handleChangeState = ({ _id, state }) => {
    axiosInterceptor();
    axiosClient
      .put(`/episode/${state ? "desactivar" : "activar"}/${_id}`)
      .then((r) => {
        let { message } = r.data;
        Swal.fire({ text: message, icon: "success" });
        handleList();
      })
      .catch(console.log);
  };
  // Images
  const handleChoose = () => {
    Swal.fire({
      text: "Esta seguro que quiere cambiar la imagen del episodio",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        let inp = document.querySelector(`#image_${i}`);
        inp.click();
      }
    });
  };
  const handleImage = () => {
    let preview = document.querySelector(`#preview${i}`);
    let frm = new FormData(document.querySelector(`#frmImage${i}`));
    let inp = frm.get("image");
    let url = URL.createObjectURL(inp);
    preview.setAttribute("src", url);
    handleUpload(frm);
  };
  const handleUpload = (form) => {
    axiosInterceptor();
    axiosClient
      .put("/episode/image/" + dato._id, form)
      .then((r) => Swal.fire({ text: r.data.message, icon: "success" }))
      .catch((e) => {
        Swal.fire({ text: "la Imagen no pudo se cambiada", icon: "error" });
        console.log(e.response.data);
      });
  };
  return (
    <>
      <tr className={`table-${dato.state ? "success" : "danger"}`}>
        <td>
          <form id={`frmImage${i}`}>
            <input
              type="file"
              id={`image_${i}`}
              name="image"
              className="d-none"
              onChange={handleImage}
            />
          </form>
          <img
            onClick={handleChoose}
            id={`preview${i}`}
            className="img-circle"
            src={`${END_POINT}/episode/image/${dato.image}`}
            height="90"
            width="90"
            alt={`image_usuario_${i}`}
          />
        </td>
        <td> {dato.title} </td>
        <td> {dato.description} </td>
        <td>
          <audio
            src={`${END_POINT}/episode/file/${dato.file}`}
            controls
            type="audio/mp3"
          >
            Este es un elemento de audio no soportado por tu navegador, prueba
            con otro
          </audio>
        </td>
        <td> {moment(dato.created).fromNow()} </td>
        <td>
          <span
            className="btn btn-warning mr-2 mt-2"
            onClick={() => handleChangeState(dato)}
          >
            {!dato.state ? (
              <i className="fas fa-toggle-off"></i>
            ) : (
              <i className="fas fa-toggle-on"></i>
            )}
          </span>

          <span
            className="btn btn-primary mr-2 mt-2"
            onClick={() => handleSelect(dato)}
          >
            <i className="fas fa-eye"></i>
          </span>
        </td>
      </tr>
    </>
  );
};

export default BodyEpisodio;
